import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "../../styles/mdx.css";

const BlogPost = ({ data }) => {
  const image = getImage(data.mdx.frontmatter.hero_image);

  return (
    <Layout
      pageTitle={data.mdx.frontmatter.title}
      description={data.mdx.frontmatter.seo_description}
    >
      <div className="blog-item-container">
        <h1 className="blog-title">{data.mdx.frontmatter.title}</h1>
        <div className="flex justify-between my-2">
          <div>by {data.mdx.frontmatter.author}</div>
        </div>
        <GatsbyImage
          image={image}
          alt={data.mdx.frontmatter.hero_image_alt}
          className="border-2 border-solid border-charcoal-500 rounded-[1rem] mb-5 mt-3"
        />
        <div className="flex justify-between pb-6 pr-1">
          <div className="text-md">
            Time to read: <strong>{data.mdx.timeToRead} minutes.</strong>
          </div>
          <div className="text-md">
            Posted: {data.mdx.frontmatter.date} | Updated:{" "}
            {data.mdx.parent.modifiedTime}
          </div>
        </div>
        <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        author
        title
        date(formatString: "MMMM D, YYYY")
        hero_image_alt
        hero_image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        seo_description
        category
      }
      timeToRead
      body
      parent {
        ... on File {
          modifiedTime(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
`;

export default BlogPost;
